import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Page from 'HOC/Page/Page';
import TextContent from 'pages/AboutUsSection/_components/TextContent/TextContent';
import FaqComponent from 'pages/AboutUsSection/_components/FaqComponent/FaqComponent';
import ContactUsComponent from 'pages/AboutUsSection/_components/ContactUsComponent/ContactUsComponent';
import InTheNews from 'pages/AboutUsSection/_components/InTheNewsComponent/InTheNewsComponent';

// const HomePage = React.lazy(() => import ('./pages/HomePage/HomePage'));
import HomePage from 'pages/HomePage/HomePage';
// const CareerAdvice = React.lazy(() => import ('pages/CareerAdvice/CareerAdvice'));
import CareerAdvice from 'pages/CareerAdvice/CareerAdvice';
// const CareerService = React.lazy(() => import('pages/CareerService/CareerService'));
import CareerService from 'pages/CareerService/CareerService';
// const AboutUsSection = React.lazy(() => import('pages/AboutUsSection/AboutUsSection'));
import AboutUsSection from 'pages/AboutUsSection/AboutUsSection';
// const CareerArticlePage = React.lazy(() => import('pages/CareerArticlePage/CareerArticlePage'));
import CareerArticlePage from 'pages/CareerArticlePage/CareerArticlePage';
// const JobsByState = React.lazy(() => import('pages/JobsByState/JobsByState'));
import JobsByState from 'pages/JobsByState/JobsByState';
// const JobsInState = React.lazy(() => import('pages/JobsInState/JobsInState'));
import JobsInState from 'pages/JobsInState/JobsInState';
// const Interview3min = React.lazy(() => import('pages/Interview3min/Interview3min'));
import Interview3min from 'pages/Interview3min/Interview3min';
// const ReviewsPage = React.lazy(() => import('pages/ReviewsPage/ReviewsPage'));
import ReviewsPage from 'pages/ReviewsPage/ReviewsPage';
// const ReviewsResultPage = React.lazy(() => import('pages/ReviewsResultPage/ReviewsResultPage'));
import ReviewsResultPage from 'pages/ReviewsResultPage/ReviewsResultPage';
// const ReviewsIndividualCompanyPage = React.lazy(() => import('pages/ReviewsIndividualCompanyPage/ReviewsIndividualCompanyPage'));
import ReviewsIndividualCompanyPage from 'pages/ReviewsIndividualCompanyPage/ReviewsIndividualCompanyPage';
// const ReviewEmployerPage = React.lazy(() => import('pages/ReviewEmployerPage/ReviewEmployerPage'));
import ReviewEmployerPage from 'pages/ReviewEmployerPage/ReviewEmployerPage';
// const NotFoundPage = React.lazy(() => import('pages/NotFoundPage/NotFoundPage'));
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';

// const TermsPage = React.lazy(() => import('pages/TermsPage/TermsPage'))
import TermsPage from 'pages/TermsPage/TermsPage';
// const PrivacyPage = React.lazy(() => import('pages/PrivacyPage/PrivacyPage'))
import PrivacyPage from 'pages/PrivacyPage/PrivacyPage';
import JobsCategories from 'pages/JobsCategories/JobsCategories';
import JobsInCategory from 'pages/JobsInCategory/JobsInCategoty';
import JobsByCategoryInState from 'pages/JobsByCategoryInState/JobsByCategoryInState';
import useHSScript from 'hooks/useHSScripts';

function App() {
  useHSScript();
  return (
      <Routes>
        <Route path='/' element={<Page component={<HomePage/>} withFooter={false}/>}/>
        <Route path='/career-advice' element={<Page component={<CareerAdvice/>}/>}/>
        <Route path='/career-advice/:articleTitle' element={<Page component={<CareerArticlePage/>}/>}/>
        <Route path='/career-service' element={<Page component={<CareerService/>}/>} />
        <Route path='/pre-interview' element={<Page component={<Interview3min/>}/>}/>
        <Route path='/jobs-by-state' element={<Page component={<JobsByState/>}/>}/>
        <Route path='/jobs-by-categories' element={<Page component={<JobsCategories/>}/>}/>
        <Route path='/jobs-for-seniors/:state' element={<Page component={<JobsInState/>}/>}/>
        <Route path='/jobs-for-seniors/category/:category' element={<Page component={<JobsInCategory/>}/>}/>
        <Route path='/jobs-for-seniors/:state/:category' element={<Page component={<JobsByCategoryInState/>}/>}/>
        <Route path='/reviews' element={<Page component={<ReviewsPage/>} />}/>
        <Route path='/reviews/companies' element={<Page component={<ReviewsResultPage/>} />}/> 
        <Route path='/reviews/companies/:companyTitle' element={<Page component={<ReviewsIndividualCompanyPage/>}/>}/>
        <Route path='/reviews/new' element={<Page component={<ReviewEmployerPage/>} />}/> 
        <Route path='/about/about-us' element={<Page component={<AboutUsSection child={<TextContent/>}/>}/>} />
        <Route path='/about/faq' element={<Page component={<AboutUsSection child={<FaqComponent/>}/>}/>} />
        <Route path='/about/contact-us' element={<Page component={<AboutUsSection child={<ContactUsComponent/>}/>}/>} />
        <Route path='/about/products-and-services' element={<Page component={<AboutUsSection child={<TextContent/>}/>}/>} />
        <Route path='/about/management-team' element={<Page component={<AboutUsSection child={<TextContent/>}/>}/>} />
        <Route path='/about/board-of-directors' element={<Page component={<AboutUsSection child={<TextContent/>}/>}/>} />
        <Route path='/about/advisory-board' element={<Page component={<AboutUsSection child={<TextContent/>}/>}/>} />
        <Route path='/about/in-the-news' element={<Page component={<AboutUsSection child={<InTheNews/>}/>}/>}/>
        <Route path='/about/speaking-engagements' element={<Page component={<AboutUsSection child={<TextContent/>}/>}/>} />
        <Route path='/about/testimonials' element={<Page component={<AboutUsSection child={<TextContent/>}/>}/>}/>
        <Route path='/not-found' element={<Page component={<NotFoundPage errorCode='404'/>} withFooter={false}/>}/>
        <Route path='/error' element={<Page component={<NotFoundPage errorCode='500'/>} withFooter={false}/>}/>
        <Route path='*' element={<Page component={<NotFoundPage errorCode='404'/>} withFooter={false}/>}/>
        <Route path='/terms-of-service' element={<Page component={<TermsPage/>}/>}/>
        <Route path='/privacy-policy' element={<Page component={<PrivacyPage/>}/>}/>
      </Routes>
  );
}

export default App;
